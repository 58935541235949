














import { T_Content } from '@/api/content'
import { defineComponent, PropType } from '@vue/composition-api'

export default defineComponent({
  props: {
    content: {
      type: Object as PropType<T_Content>,
      required: true
    }
  },
  methods: {
    getNum(num = 0) {
      return num > 10000
        ? (num / 10000).toFixed(2) + 'w'
        : num
    }
  }
})
