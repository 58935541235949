



























import { T_ContentMenu } from '@/api/content'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'

export default defineComponent({
  props: {
    contentMenus: {
      type: Array as PropType<T_ContentMenu[]>,
      default: () => []
    }
  },
  emits: ['select'],
  setup(props, { emit }) {
    const activeMenuId = ref('')
    const selectedMenu = computed(() => {
      return props.contentMenus.filter(menu => menu.id === activeMenuId.value)[0]
    })

    const activeChildMenuId = ref<string>()

    changeMenu(props.contentMenus[0])

    function changeMenu(menu: T_ContentMenu) {
      if (activeMenuId.value === menu.id) {
        return
      }
      activeMenuId.value = menu.id
      activeMenuId.value && emit('select', activeMenuId.value)
      if (menu.childMenuList && menu.childMenuList.length) {
        changeChildMenu(menu.childMenuList[0])
      } else {
        activeChildMenuId.value = ''
      }
    }

    function changeChildMenu(menu: T_ContentMenu) {
      if (activeChildMenuId.value === menu.id) {
        return
      }
      activeChildMenuId.value = menu.id

      activeChildMenuId.value && emit('select', activeChildMenuId.value)
    }

    return {
      activeMenuId,
      activeChildMenuId,
      selectedMenu,
      changeChildMenu,
      changeMenu
    }
  }
})
