import { getCurrentInstance } from "@vue/composition-api";
import VueRouter, { Route } from "vue-router";

export function useRouter() {
  const ctx = getCurrentInstance()

  return ctx?.proxy.$router as VueRouter
}

export function useRoute() {
  const ctx = getCurrentInstance()

  return ctx?.proxy.$route as Route
}