import { reactive, ref } from '@vue/composition-api'
import { AxiosRequestConfig } from 'axios'


export enum CustomerOrderNum {
  READING = 'READING',
  DURATION = 'DURATION'
}

export type T_PageParams = {
  pageNumber: number,
  pageSize: number
}

export type T_PageRes<T> = {
  data: T,
  records?: T,
  page: {
    totalElements?: number,
    size: number,
    pageSize?: number,
    pageNum?: number,
    total?: number
  }
}

type T_UseListParams<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  api: (params?: any, options?: any) => Promise<T_PageRes<T[]>>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchParams?: Record<string, any>,
  autoLoad?: boolean,
}

export function useList<T>(params: T_UseListParams<T>) {
  const {
    api, searchParams, autoLoad = true
  } = params

  const total = ref(0)
  const list: T[] = reactive([])
  const loading = ref(false)
  const hasLoaded = ref(false) // 是否加载过列表
  const pageParams: T_PageParams = reactive({
    pageNumber: 1,
    pageSize: searchParams?.pageSize || 10,
  })
  const finished = ref(false)
  const controller = ref<any>();  



  function load() {
    if(controller.value) {
      controller.value.abort()
    }
    controller.value = new AbortController();
    loading.value = true
    hasLoaded.value = false
    console.log('hasLoaded.value', hasLoaded.value, list.length)

    api({
      ...pageParams,
      ...searchParams,
    }, {
      signal: controller?.value?.signal,
    }).then(res => {
      const resList = res.data || res.records || res
      list.push(...resList)
      // list.push(...[])
      pageParams.pageNumber++
      
      if (res.page) {
        const resTotal = res.page.size || res.page.totalElements as number || res.page.total
        total.value = resTotal as number
        if (resList.length < pageParams.pageSize) { // 强制适配服务的 totalElements 计算错误
          total.value = list.length
        }
      } else {
        // 适配无 page 返回. 使用 list 是否为空判断
        resList.length === 0 ?
          (total.value = list.length) :
          (total.value = list.length + 1) // 如果 list 不为 [], 设置为总长度大于现有长度
      }
      loading.value = false
      hasLoaded.value = true

      finished.value = list.length >= total.value && hasLoaded.value
    }).catch((error) => {
      // 如果服务报错. 修改 total 值, 避免反复加载
      total.value = list.length
    }).finally(() => {
      // finished.value = false

    })
  }

  function search() {
    reload()
  }

  function reload() {
    list.length = 0
    pageParams.pageNumber = 1
    load()
  }

  if (autoLoad) {
    load()
  }

  return {
    total,
    loading,
    list,
    finished,
    hasLoaded,

    load,
    search,
    reload
  }
}