




















































import { ContentType, getNewContentList, T_Content } from '@/api/content'
import { useProfile } from '@/hook/useBaseInfo'
import { T_PageParams, T_PageRes, useList } from '@/hook/useList'
import { formatSeconds, getReadTimeByWords } from '@/shared/utils'
import { defineComponent, PropType, watch } from '@vue/composition-api'
import ContentStatistics from '@/components/Content/ContentStatistics.vue'
import { formatDate } from '@/shared/date'

export default defineComponent({
  components: {
    ContentStatistics,
  },
  props: {
    searchParams: {
      type: Object,
      default: () => ({}),
    },
    api: {
      type: Function as PropType<(p: T_PageParams) => Promise<T_PageRes<T_Content[]>>>,
      default: getNewContentList,
      // default: getContentList
    },
  },
  setup(props) {
    const contentListRefs = useList({
      api: props.api,
      searchParams: props.searchParams,
      autoLoad: false,
    })
    const { toContentDetailRoute } = useProfile()

    watch(props.searchParams, () => {
      // if (contentListRefs.loading.value) {
      //   return
      // }
      contentListRefs.hasLoaded.value = false
      contentListRefs.reload()
    })

    return {
      ...contentListRefs,
      toContentDetailRoute,
    }
  },
  methods: {
    isVideo(content: T_Content) {
      return content.contentType === ContentType.VIDEO
    },
    getReadTime(content: T_Content) {
      const duration = content.duration
      return formatSeconds(duration)
    },
    hasCover(content: T_Content) {
      return content.coverPics && content.coverPics.length
    },
    getContentDesc(content: T_Content) {
      const descMap: Record<string, string> = {
        [ContentType.ARTICLE]: `文章共计 ${content.words || 0} 字, 预计阅读 ${getReadTimeByWords(content.words || 0)}`,
        [ContentType.MORNING_PAPER]: content.words ? `早报共计 ${content.words || 0} 字, 预计阅读 ${getReadTimeByWords(content.words || 0)}` : '',
        [ContentType.VIDEO]: `视频时长 ${this.getReadTime(content)}`,
      }

      return descMap[content.contentType] || ''
    },
    formatDate,
  },
})
