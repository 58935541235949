

























import { defineComponent, nextTick, reactive, ref } from '@vue/composition-api'
import { getContentMenus, T_ContentMenu } from '@/api/content'
import ContentList from '@/components/Content/ContentList.vue'
import ContentMenus from '@/components/Content/ContentMenus.vue'
import ContentSearch from '@/components/Content/ContentSearch.vue'
import { useRoute } from '@/hook/useRouter'
export default defineComponent({
  components: {
    ContentList,
    ContentMenus,
    ContentSearch,
  },
  setup() {
    const route = useRoute()
    const riversAppId = route.params.riversAppId
    const { contentMenus, loadMenus } = useContentMenus(riversAppId)
    const contentMenusRef = ref<{ $el: HTMLElement }>()
    const contentSearchRef = ref<{ $el: HTMLElement }>()
    const contentListEl = ref<HTMLElement>()
    const searchParams = reactive({
      contentPollId: '',
      keyword: '',
    })

    loadMenus()

    function onMenuSelected(menuId: string) {
      searchParams.contentPollId = menuId
      setContentListTop()
    }

    function onSearchKeyword(keyword: string) {
      searchParams.keyword = keyword
      if (contentListEl.value) {
        contentListEl.value.scrollTop = 0
      }
      setContentListTop()
    }

    function setContentListTop() {
      nextTick(() => {
        const menuHeight = contentMenusRef.value?.$el.clientHeight || 28
        const searchHeight = contentSearchRef.value?.$el.clientHeight || 0
        if (contentListEl.value) {
          const menuCalcHeight = searchParams.keyword ? 0 : menuHeight
          contentListEl.value.style.top = menuCalcHeight + searchHeight + 5 + 'px'
          contentListEl.value.scrollTop = 0
        }
      })
    }

    return {
      contentMenus,
      searchParams,
      onMenuSelected,
      onSearchKeyword,

      contentMenusRef,
      contentSearchRef,
      contentListEl,
    }
  },
})

function useContentMenus(riversAppId: string) {
  const contentMenus = reactive<T_ContentMenu[]>([])

  function loadMenus() {
    getContentMenus().then(list => {
      if (riversAppId === '1650449978797084674') {
        const newlist: {
          order: number
          id: string
          name: string
          childMenuList: T_ContentMenu[]
        }[] = [
          {
            id: 'morningPaper',
            name: '早报通',
            order: 1,
            childMenuList: [],
          },
          {
            id: 'operation',
            name: '运营通',
            order: 2,
            childMenuList: [],
          },
          {
            id: 'talent',
            name: '达人通',
            order: 3,
            childMenuList: [],
          },
        ]
        newlist.forEach(item => {
          item.childMenuList.push(...list.filter(i => i.name.includes(item.name)).map(cI => ({ ...cI, name: cI.name.split('-')[1] })))
        })
        contentMenus.push(...newlist)
      } else if (riversAppId === '1492351926900203522') {
        const newlist: {
          order: number
          id: string
          name: string
          childMenuList: T_ContentMenu[]
        }[] = [
          {
            id: 'century',
            name: '百年专属',
            order: 1,
            childMenuList: [],
          },
          {
            id: 'copyright',
            name: '版权内容',
            order: 2,
            childMenuList: [],
          },
        ]
        newlist.forEach(item => {
          item.childMenuList.push(...list.filter(i => i.name.includes(item.name)).map(cI => ({ ...cI, name: cI.name.split('-')[1] })))
        })
        contentMenus.push(...newlist)
      } else {
        contentMenus.push(...list)
      }
    })
  }

  return {
    contentMenus,
    loadMenus,
  }
}
