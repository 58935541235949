








































import { defineComponent, reactive, ref } from '@vue/composition-api'
import { getContentSearchKeywords, setContentSearchKeyword } from '@/api/content'

export default defineComponent({
  emits: ['confirm'],
  setup(props, { emit }) {
    const keyword = ref('')
    const {
      historyKeywords, recommendKeywords, loadKeywords
    } = useSelectHistory()
    const {
      isShowSelectPanel, showSelectPanel, hideSelectPanel,isSearchError
    } = useSelectPanel()

    loadKeywords()

    function onEntrySearch() {
      if(keyword.value.trim() === ''){
        isSearchError.value = true
        return
      }
      emitConfirm(keyword.value)
      hideSelectPanel()
    }

    function onEntryBlur() {
      // blue 为空时, 需自动清空搜索
      // if (keyword.value.trim() === '') {
      //   isSearchError.value = true
      //   return
      //   // 没有值得时候不应该触发搜索，需展示推荐内容
      //   // emitConfirm(keyword.value)
      // }
      emitConfirm(keyword.value)
      hideSelectPanel()
    }

    function onClickKeyword(tag: string) {
      keyword.value = tag
      emitConfirm(keyword.value)
      hideSelectPanel()
    }

    function emitConfirm(keyword: string) {
      isSearchError.value = false
      emit('confirm', keyword)
      setContentSearchKeyword(keyword).finally(() => {
        loadKeywords()
      })
    }

    return {
      keyword,
      historyKeywords,
      recommendKeywords,
      onEntrySearch,
      onEntryBlur,
      onClickKeyword,
      isShowSelectPanel, showSelectPanel, hideSelectPanel,
      isSearchError
    }
  }
})

function useSelectHistory() {
  const historyKeywords = reactive<string[]>([])
  const recommendKeywords = reactive<string[]>([])

  function loadKeywords() {
    getContentSearchKeywords().then(res => {
      historyKeywords.length = 0
      recommendKeywords.length = 0
      historyKeywords.push(...res.historyKeywords)
      recommendKeywords.push(...res.recommendKeywords)
    })
  }

  return {
    historyKeywords,
    recommendKeywords,
    loadKeywords
  }
}

function useSelectPanel() {
  const isShowSelectPanel = ref(false)
  const isSearchError = ref(false)


  function showSelectPanel() {
    isShowSelectPanel.value = true
    isSearchError.value = false
  }

  function hideSelectPanel() {
    setTimeout(() => {
      isShowSelectPanel.value = false
    })
  }

  return {
    isShowSelectPanel,
    showSelectPanel,
    hideSelectPanel,
    isSearchError
  }
}
